import React from "react";
import {Helmet} from "react-helmet";
import Layout, { Headline, SubIMG } from '../../components/layout';
import CarouselTestimonials from "../../components/carousels/testimonials";
import bg from "../../assets/img/hero2.jpg";
import sub from "../../assets/img/6597.jpg"
import Telephone from '../../components/telephone/telephone';

export default function AboutUs() {
    return (
        <Layout
            bg={bg}
            header={"During These Uncertain Times... "}
            subheader={"WE ARE THE FAMILY BUSINESS YOU CAN TRUST - Over 30yrs Experience in the Real Estate Industry!"}
        >
            <Helmet>
                <title>About Direct Buyers</title>
                <meta name="description" content="Our team at Direct Buyers has extensive experience." />
            </Helmet>

            <div className={`container`}>
                <Headline><h1>About the Direct Buyers Team</h1></Headline>

                <div className={`row`}>
                    <div className={`col-md-6`}>
                        <p>SELLING TO DIRECT BUYERS IS A FAST & RELIABLE WAY TO SELL YOUR HOME  HASSLE-FREE!</p>
                        <p>The Direct Buyers team are local Southern California residents, just like you! After years of working with homeowners, and earning their trust, we have emerged as one of the leading local home buyers in Southern California. Direct Buyers is a family owned business with over 30 years experience in the Real Estate industry. We understand the challenges and hardships of property ownership and strive to help owners with each of their unique circumstances. We specialize in assisting local property owners sell their properties  fast for CASH! Whether you need to sell fast for financial reasons; or just do not want to deal with realtors, open houses and buyers struggling to get loans - we can help with ANY circumstances! If you have inherited a home that needs to go thru probate, or maybe it has sat vacant for years and is now a hoarder house - Call us, we have helped hundreds of homewoners in similar situations.</p>

                        <p>BENEFITS WHEN SELLING TO DIRECT BUYERS: PERSONAL SERVICE!</p>
                        <p>At Direct Buyers, we understand listing your property for sale through traditional real estate channels is stressful and may not always the best option for all property owners. When selling your home to Direct Buyers, we are able to buy your house FAST and AS-IS  - sometimes sight unseen! The best part is you don't pay realtor commissions or ANY fees! No repairs or  waiting on appraisals or  banks to approve the loan - we are ALWAYS ready! We close on YOUR schedule, whether that's a quick 10 day escrow or if you need a few months to prepare for the sale. We promise  a Close GUARANTEE or you keep our DEPOSIT!</p>
                        <p>If you need to sell your home FAST, and need a reliable buyer WE ARE YOUR BUYER! You can rest assured we will close escrow on time! We are ready to close when you are! Don't just take our word for it, read what recent homeowners have to say.</p>
                        <p>Call today to learn how we can beat our competitors' written offers! We’ve helped hundreds of homeowners and will do the same for you!  Call us at <Telephone/> for your free cash offer!</p>
                    </div>
                    <div className={`col-md-5 offset-md-1`}>
                        <SubIMG src={sub} alt="" />
                    </div>
                </div>

                <CarouselTestimonials />

            </div>
        </Layout>
    );
}
